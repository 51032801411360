.nav-bar{
    border-bottom: 2px;
}


button{
    padding-top: 56px;
}

button:hover{
    scale: 1.1;
}


.icon-hover:hover{
    scale: 1.4;
}

a{
    font-weight: bold;
}


.bg-patient{
   
    border-radius: 30px;
}


.bg-patient-info{
    /* background-color:#ffffff; */
    color: black;
}

.primary-color{
    background-color: #1B4965;
    color: white;
}
