.App {
  /* text-align: center; */
  /* background-color: rgb(241, 241, 241); */
}
:root {
  --app-bg-color: #fafafa; /* Default background color */
}

.app-bg-color {
  background-color: var(--app-bg-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  color: #fff;
  margin-right: 1rem;
}

.nav-link-dropdown {
  color: #000;
}

.nav-link.active {
  color: #0d6efd;
  font-weight: bold;
}

.nav-link:hover,
.nav-link.active:hover {
  text-decoration: none;
  color: #0d6efd;
}

.label-value-root {
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;
}

.label-value-label {
  font-weight: bold;
  margin-bottom: 8px;
}

.label-value-value {
  color: #1976d2;
  font-weight: bold;
}


.t-header {
  background-color: #61dafb;
}

hr{
  margin: 0 0 1rem 0;
}
.bg-avatar{
  background-color: #61dafb;
}

.floatingNote{position: 'fixed';
  z-index: 9999;

  padding: 2;
  box-shadow: 4;
  cursor: 'move'}


  .drawing-path {
    mix-blend-mode: destination-out;
  }

  .toolbarContainer {
    background-color: #fff5ee;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
border-top-left-radius: 5;
border-top-right-radius:5 ;    /* justify-content: space-between; */
    padding: 8px;
  }
  
  .toolbarColorSelector {
    display: flex;
    align-items: center;
  }
  
  .toolbarPenSize {
    display: flex;
    align-items: center;
    width: "200px";
  }
  
  .toolbarEraser {
    display: flex;
    align-items: center;
  }
  
  .toolbarActionButtons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .tlui-layout__top__right {
    text-align: right;
  }
  
  .exit-full-screen-button {
    margin-right: 10px;
  }